export enum API_TYPES {
  ASSISTANT_API = 'ASSISTANT_API',
}

const developmentApi: Record<API_TYPES, string> = {
  // ASSISTANT_API: 'https://open-pa-api-ch-elektra.os-dev.io/api',
  ASSISTANT_API: 'https://open-pa-api-ch-hulk.os-dev.io/api',
  // ASSISTANT_API: 'https://open-pa-api-ch-witch.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  ASSISTANT_API: 'https://open-pa-api-vapt-blade.os.wpp.com/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
